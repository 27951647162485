import { filter, find, isEmpty } from 'lodash';
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import URI from 'urijs';

import { Row, Col, Button, Tabs, Text, ShowPopup, Popup, colors } from '@commonsku/styles';
import withReducers from '../../store/withReducers';
import communityEventsSlice, { fetchUpcomingEvents, fetchOnceCommunityEvents } from './communityEventsSlice';
import { getIdentityUtils, setPageInterval, clearPageInterval, COMMONSKU_COMPANY_ID } from '../../utils';
import { useIdentity } from '../../hooks';
import { useUpcomingPastCommunityEvents } from './hooks';
import ScheduleEventPopup from './ScheduleEventPopup';
import EventList from './EventList';

const PastEventsTab = ({ selected_id, onSelect, events }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOnceCommunityEvents());
  }, []);
  const commonskuEvents = filter(events, { company_id: COMMONSKU_COMPANY_ID });
  return <EventList selected_id={selected_id} onSelect={onSelect} events={commonskuEvents} placeholder={
    <Col>
      <Row>View recordings of previously held supplier events that have ended.</Row>
      <Row>Past events will be displayed here after their scheduled end time.</Row>
      <Row>{'You can click on "view collection" to view the associated product collection.'}</Row>
    </Col>
  }/>;
};

const CommunityEventsApp = ({ initialTab }) => {
  const uri = new URI();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const identity = useIdentity();
  const [upcoming, past] = useUpcomingPastCommunityEvents();
  const { isAdmin } = getIdentityUtils(identity);
  const selected_id = uri.search(true).community_event_id;
  const [selectedId, setSelectedId] = useState(selected_id);
  const [loading, setLoading] = useState(true);
  const tabIndex = initialTab === 'past-events' ? 1 : 0;

  useEffect(() => {
    dispatch(fetchUpcomingEvents()).then(() => {
      setLoading(false);
    });
    const intervalId = setPageInterval(() => {
      dispatch(fetchUpcomingEvents());
    }, 1000 * 60 * 5);

    return () => {
      clearPageInterval(intervalId);
    };
  }, []);

  const onSelect = (id) => {
    setSelectedId(id);
    if (ref.current) {
      ref.current.scrollTop = 0;
    }
  };

  return <div style={{ padding: '15px 35px', width: '100%', height: '100%' }}>
    <Row flex justify="space-between" style={{
      position: 'sticky', top: 0, padding: 3,
    }}>
      <Text style={{
        fontSize: '1.8rem', fontWeight: 500, alignSelf: 'center', padding: '3px 0 0 3px', color: colors.texttitle
      }}>Webinars</Text>
      {isAdmin() && <ShowPopup popup={ScheduleEventPopup} render={({ onClick }) => {
        return <Button cta ml={10} onClick={onClick}>Host Event</Button>;
      }}/>}
    </Row>
    <div ref={ref} style={{ width: '100%', height: '100%', overflow: 'auto' }}>
      {!loading && <Tabs
        // if initial selected_id is a past event, then display past events tab
        selectedTabIndex={
          selectedId && selected_id === selectedId && !find(upcoming, { community_event_id: selectedId })
            ? 1 : tabIndex
        }
        tabs={[
          {
            label: 'Upcoming Webinars',
            content: <EventList selected_id={selectedId} onSelect={onSelect} events={upcoming}/>
          },
          {
            label: 'Past Webinars',
            content: <PastEventsTab selected_id={selectedId} onSelect={onSelect} events={past}/>,
          }
        ]}
      />}
    </div>
    {
      (
        !loading && !isEmpty(past) && selected_id && selected_id === selectedId
        && !find(upcoming.concat(past), { community_event_id: selected_id })
      ) && <ShowPopup popup={Popup} title="Can't view this event" width="600px" height="auto" autoOpen={true}>
        <Text style={{ padding: '15px 8px', fontSize: '1.2rem', display: 'inline-block' }}>
          You don’t have permission to view this event.
        </Text>
      </ShowPopup>
    }
  </div>;
};

export default withReducers(CommunityEventsApp, {
  community_events: communityEventsSlice.reducer
});

