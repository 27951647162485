import moment from 'moment-timezone';
import { includes, defaults, get, map, find, filter, pick, isEmpty, isEqual, toInteger } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  Col, Row, Button, Label, LabeledInput, Popup, Input, LabeledSelect, Textarea, LabeledRadioGroup
} from '@commonsku/styles';
import { useIdentity } from '../../hooks';
import { COMMONSKU_COMPANY_ID, getIdentityUtils } from '../../utils';
import { useCompanies } from './hooks';
import DateInput from '../DateInput';
import { fetchCompanies, fetchCompanyUsers, fetchCollections, createCommunityEvent } from './communityEventsSlice';

const ScheduleEventPopup = ({ event, onClose }) => {
  const datetime_format = 'YYYY-MM-DD HH:mm';
  const dispatch = useDispatch();
  const companies = useCompanies();
  const identity = useIdentity();
  const { isAdmin } = getIdentityUtils(identity);
  const [state, setState] = useState(defaults({}, event, {
    name: '', scheduled_time: '', audience: 'DISTRIBUTOR', collection_id: null, description: '', audiences: [],
    company_id: isAdmin() ? '' : identity.company_id, company_type: 'SUPPLIER', duration: 30, zoom_type: 'WEBINAR'
  }));
  const [hostCompanyType, setHostCompanyType] = useState(state.company_id === COMMONSKU_COMPANY_ID ? 'COMMONSKU' : 'SUPPLIER');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(fetchCompanies());
  }, []);

  useEffect(() => {
    if (state.company_id) {
      dispatch(fetchCompanyUsers({ company_id: state.company_id, company_type: state.company_type }));
      dispatch(fetchCollections(state.company_id));
    }
  }, [state.company_id]);

  const change = (name) => (e) => {
    return setState({...state, [name]: e.target.value});
  };
  const changeStartTime = (date) => {
    setState({...state, scheduled_time: date && moment(date).toJSON() });
  };
  const isNewEvent = !state.community_event_id;
  const zoomTypeOptions = filter([
    {label: 'webinar', value: 'WEBINAR'},
    {label: 'meeting', value: 'MEETING'},
  ], (option) => {
    return isNewEvent || (option.value === event.zoom_type);
  });
  const hostCompany = get(companies, [state.company_id]);
  const hostCompanyTypeOptions = [
    {label: 'commonsku', value: 'COMMONSKU'},
    {label: 'supplier', value: 'SUPPLIER'},
  ];
  const companyOptions = map(filter(companies, { company_type: 'SUPPLIER' }), (company) => {
    return {label: company.company_name, value: company.company_id};
  });
  const userOptions = map((hostCompany || {}).users, (user) => {
    const selected = state.user_id === user.user_id;
    return {
      label: <Col px={5} py={5}>
        <Row>{user.contact_first_name} {user.contact_last_name}</Row>
        {!selected && <Row>{user.position}</Row>}
        {!selected && <Row>{user.contact_email}</Row>}
      </Col>,
      value: user.user_id
    };
  });
  const collectionOptions = map((hostCompany || {}).collections, (collection) => {
    return {
      label: collection.title,
      value: collection.order_id
    };
  });

  return <Popup title="Schedule Event" onClose={onClose} style={{ maxWidth: 650 }} controls={<Row justify="flex-end">
    <Button onClick={onClose}>Cancel</Button>
    <Button cta ml={10} onClick={async () => {
      const newErrors = {};
      const required = ['name', 'scheduled_time', 'audience', 'user_id', 'company_id'];
      map(pick(state, required), (v, k) => {
        if (isEmpty(v)) {
          newErrors[k] = `${k} can not be empty`;
        }
      });
      if (toInteger(state.duration) <= 0) {
        newErrors.duration = `duration can not be ${state.duration}`;
      }
      if (!isEqual(newErrors, errors)) {
        setErrors(newErrors);
      }
      if (isEmpty(newErrors)) {
        await dispatch(createCommunityEvent(state));
        onClose();
      }
    }}>Save</Button>
  </Row>}>
    <Col px={6} py={5}>
      {map(errors, (error, i) => {
        return <div key={i} style={{color: 'red'}}>{error}</div>;
      })}
      <Row py={5}>
        <Col pr={5} style={{ flex: '0 0 50%' }}>
          <LabeledInput label="Event name" value={state.name} onChange={change('name')}/>
        </Col>
        {isAdmin() && isNewEvent && <Col pl={5} style={{ flex: '0 0 50%' }}>
          <Label>Date ({moment.tz.guess()})</Label>
          <DateInput
            value={state.scheduled_time && moment(state.scheduled_time).format(datetime_format)}
            onChange={changeStartTime}
            onBlur={changeStartTime}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={30}
            timeCaption="time"
            inputFormat={datetime_format}
            outputFormat={datetime_format}
            dateFormat={datetime_format.replace('YYYY', 'yyyy').replace('DD', 'dd')}
            placeholder={datetime_format}
            customInput={<Input/>}
          />
        </Col>}
      </Row>
      {isAdmin() && <>
        <Row py={5}>
          <LabeledRadioGroup
            value={state.zoom_type}
            radios={zoomTypeOptions}
            onChange={(e) => {
              setState({ ...state, zoom_type: e.target.value });
            }}/>
        </Row>
        <Row py={5}>
          <LabeledRadioGroup
            value={hostCompanyType}
            radios={hostCompanyTypeOptions}
            onChange={(e) => {
              setHostCompanyType(e.target.value);
              const newState = {
                  company_id: '', company_type: 'SUPPLIER', collection_id: '', user_id: ''
              };
              if (e.target.value === 'COMMONSKU') {
                newState.company_id = COMMONSKU_COMPANY_ID;
                newState.company_type = 'DISTRIBUTOR';
              }
              setState({
                ...state,
                ...newState,
              });
            }}/>
        </Row>
        <Row py={5}>
          {hostCompanyType === 'SUPPLIER' && <Col pr={5}>
            <LabeledSelect label="Host Company" placeholder="None"
              value={find(companyOptions, { value: state.company_id })}
              onChange={({ value }) => {
                setState({...state, company_id: value});
              }}
              options={companyOptions}
            />
          </Col>}
          <Col pl={5}>
            <LabeledSelect label="Host" placeholder="None"
              value={find(userOptions, { value: state.user_id })}
              onChange={({ value }) => {
                setState({...state, user_id: value});
              }}
              options={userOptions}
              filterOption={(candidate, input) => {
                if (input) {
                  const user = find((hostCompany || {}).users, { user_id: candidate.value });
                  return includes(
                    `${user.login_name} ${user.contact_email} ${user.contact_first_name} ${user.contact_last_name}`,
                    input
                  );
                }
                return true;
              }}
            />
          </Col>
        </Row>
      </>}
      {hostCompanyType === 'SUPPLIER' && <Row py={5}>
        <LabeledSelect label="Product Collection"  placeholder="None" parentStyle={{ flex: 1 }}
          value={find(collectionOptions, { value: state.collection_id })}
          onChange={({ value }) => {
            setState({...state, collection_id: value});
          }}
          options={collectionOptions}
        />
      </Row>}
      {/* <Label>Audience</Label>
      <LabeledRadioGroup name="audience" value={state.audience} onChange={change('audience')}
        radios={[
          {label: 'Suppliers', value: 'SUPPLIER'},
          {label: 'Distributors', value: 'DISTRIBUTOR'},
          {label: 'Everyone', value: 'ALL'},
          {label: 'Only selected companies', value: 'SELECTED'},
        ]}
      />
      {state.audience === 'SELECTED' && <Select
        isMulti={true}
        onChange={(options) => {
          setState({...state, audiences: map(options, ({ value }) => value)});
        }}
        options={map(companies, (company) => {
          return {label: company.company_name, value: company.company_id};
        })}
      />}
      <LabeledTextarea label="Post it to the community feed" onChange={change('message')}/> */}
      {isAdmin() && !get(event, 'start_time') && <Row py={5}>
        <LabeledInput label="Meeting Duration (min)" value={state.duration} onChange={(e) => {
          return setState({...state, duration: toInteger(e.target.value)});
        }}/>
      </Row>}
      <Row py={5}>
        <Label>Description</Label>
        <Textarea flex maxLength={300} rows={4} value={state.description} onChange={change('description')}/>
      </Row>
    </Col>
  </Popup>;
};

export default ScheduleEventPopup;
