import { map, find, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';

import { Background, Row } from '@commonsku/styles';
import Event from './Event';
import { window } from '../../global';

const EventList = ({ events, selected_id=null, onSelect, placeholder='' }) => {
  const mediaMatch = window.matchMedia('(min-width: 1024px)');
  const [matches, setMatches] = useState(mediaMatch.matches);
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  }, []);
  useEffect(() => {
    setSelected(find(events, { community_event_id: selected_id }));
  }, [selected_id, events]);

  return <Background flex px={10} py={10} style={{ flexWrap: 'wrap' }}>
    {isEmpty(events)
      ? <Background flex padded bg="#FFFFFF" px={10} py={10} mx={10} my={10} style={{ flex: 1 }}>
        {placeholder}
      </Background>
      : <>
        {selected && <Row px={10} py={10} style={{ flex: '0 0 100%', overflow: 'hidden' }}>
          <Event size="medium" event={selected}/>
        </Row>}
        {map(events, (event, i) => {
          return event !== selected && <Row key={i} px={10} py={10} style={{
            flex: matches ? '0 0 50%' : '0 0 100%',
            overflowX: 'hidden', overflowY: 'auto'
          }}>
            <Event key={i} event={event} onSelect={(e) => {
              setSelected(event);
              onSelect(event.community_event_id);
            }}/>
          </Row>;
        })}
      </>
    }
  </Background>;
};

export default EventList;