import { get, filter, concat, map, isEmpty, trim } from 'lodash';
import moment from 'moment-timezone';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import {
  Avatar, Button, IconButton, Background, Col, Row, ShowPopup, Link, Text, Popup, Input, DownloadIcon, colors
} from '@commonsku/styles';
import { getImageSrc, getAbsoluteUrl, getIdentityUtils, createDownload, oauth, parseRestBoolean } from '../../utils';
import { useIdentity } from '../../hooks';
import { createCommunityEventUser, deleteCommunityEventUser } from './communityEventsSlice';
import { Calendar } from '../moment';
import CompanyLink from '../CompanyLink';
import ScheduleEventPopup from './ScheduleEventPopup';
import { window, document } from '../../global';

const InterestedPopup = ({ onClose }) => {
  return <Popup onClose={onClose} title="Thanks for your interest!" width="auto" height="auto">
    <Row px={8} py={10}>
      A confirmation will be emailed to you shortly with a Zoom link and an option to add this event to your calendar.
    </Row>
  </Popup>;
};

const Interested = ({ event, style, ...props }) => {
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  const interested = !isEmpty(event.community_event_user_id);

  return <ShowPopup popup={InterestedPopup} render={({ onClick }) => {
    return interested
      ? <Button {...props} bg={colors.special2} style={{ ...style, borderColor: colors.special2 }}
        onClick={(e) => {
          dispatch(deleteCommunityEventUser(event.community_event_user_id));
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >&#10003;{hover ? ' Not' : ''} Attending</Button>
      : <Button {...props} style={style} onClick={(e) => {
        dispatch(createCommunityEventUser({ community_event_id: event.community_event_id })).then(() => {
          onClick(e);
        });
      }}>{"I'm Attending"}</Button>
    ;
  }}/>;
};

const SharePopup = ({ event, onClose }) => {
  const ref = useRef(null);

  useEffect(() => {
    ref.current.focus();
    ref.current.select();
  }, []);

  return <Popup onClose={onClose} title="Share Event" width="auto" height="auto"
    controls={<span
      style={{ fontSize: '1.6rem', opacity: 0.5, cursor: 'pointer', marginTop: '-10px' }} onClick={onClose}
    >&times;</span>}
  >
    <Row py={20} style={{ minWidth: 500 }}>
      <Input ref={ref} readOnly autoFocus
        value={getAbsoluteUrl(`community_events.php?community_event_id=${event.community_event_id}`)}
        style={{ flex: '1 1 360px', height: 46, marginBottom: 0, marginRight: 10 }}
      />
      <Button flex onClick={() => {
        ref.current.focus();
        ref.current.select();
        document.execCommand('copy');
      }}>Copy</Button>
    </Row>
    <Row>A commonsku account is required to view events</Row>
  </Popup>;
};

const Event = ({ event, size: uiSize="small", onSelect }) => {
  const mediaMatch = window.matchMedia('(min-width: 480px)');
  const dispatch = useDispatch();
  const identity = useIdentity();
  const [matches, setMatches] = useState(mediaMatch.matches);
  const { isAdmin } = getIdentityUtils(identity);
  const interested = !isEmpty(event.community_event_user_id);
  const company = event.company || {};
  const company_type = identity.company_type === 'TENANT' ? 'DISTRIBUTOR' : identity.company_type;

  useEffect(() => {
    const handler = e => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  }, []);

  const avatarSize = {small: 32, medium: 42}[uiSize];
  const style = {
    small: {
      title: { fontWeight: 'bold', fontSize: '1.5rem' },
      text: { fontSize: '1rem' },
      button: { margin: '5px 5px 5px 0', minHeight: 36 },
    },
    medium: {
      title: { fontWeight: 'bold', fontSize: '2rem' },
      text: { fontSize: '1.2rem' },
      button: { margin: '5px 5px 5px 0', minHeight: 36 },
    },
  }[uiSize];

  const hostFullName = filter(['contact_first_name', 'contact_last_name'].map((field) => {
    return trim(get(event, `host.${field}`));
  })).join(' ');
  return <Background flex padded bg="#FFFFFF" style={{
    flex: '1 1 auto', flexDirection: 'column',
    minHeight: matches ? {small: 250, medium: 320}[uiSize] : 360, minWidth: 360,
  }}>
    <Row>
      <Col style={{ flexDirection: 'column' }} flex pr={20}>
        <Row><Link style={style.title} onClick={onSelect}>{event.name}</Link></Row>
        <Row><CompanyLink style={style.text} company={company}/></Row>
        <Row>
          <Text style={style.text}><Calendar value={event.scheduled_time}/> ({moment.tz.guess()})</Text>
        </Row>
        {parseRestBoolean(get(event, 'host.active')) && <Row justify={'flex-start'} style={{ alignItems: 'center' }}>
          <Avatar mr={10} mt={10} size={uiSize} pic={getImageSrc(event.host, 'small', {
            defaultImage: '/images/user-avatar3-120x120.png'
          })}/>
          {isEmpty(hostFullName) ? null : <Text style={style.text}>Hosted by {hostFullName}</Text>}
        </Row>}
        {uiSize === 'medium' && <Row>{event.description}</Row>}
      </Col>
      {/*<Col style={{ flex: `0 0 ${avatarSize * 4}px` }}>*/}
      {/*  <Avatar pic={getImageSrc(company, 'medium', {addCropPrefix: true})} size={`${avatarSize * 4}px`}/>*/}
      {/*</Col>*/}
    </Row>
    <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
      <Row style={{ flexWrap: 'wrap' }} justify={'flex-start'}>
        {!event.start_time && identity.user_id !== event.user_id && <Interested
          key={'interested'} event={event} size={uiSize} style={style.button}
        />}
        {(identity.user_id === event.user_id || isAdmin()) && <ShowPopup
          key={'edit'} popup={ScheduleEventPopup} event={event}
          render={({ onClick }) => {
            return <Button size={uiSize} style={style.button} onClick={onClick}>Edit</Button>;
          }}
        />}
        {
          (!event.end_time && (event.start_time || moment().add(5, 'minutes').isAfter(event.scheduled_time))) &&
          <Button size={uiSize} style={style.button} onClick={(e) => {
            if (isAdmin()) {
              window.open(event.zoom_link);
            } else if (!interested) {
              dispatch(createCommunityEventUser({ community_event_id: event.community_event_id }))
                .then(({ community_event_user }) => {
                  if (community_event_user) {
                    window.open(community_event_user.join_url);
                  }
                })
              ;
            } else {
              window.open(event.join_url);
            }
          }}>Join on Zoom</Button>
        }
        {event.recording_url && <Button size={uiSize} style={style.button} onClick={(e) => {
          window.open(`/video.html?src=${encodeURIComponent(event.recording_url)}`);
        }}>Watch Recording</Button>}
        {event.collection_id && (isAdmin() || identity.company_id === event.company_id || event.start_time) && <Row>
          <Button size={uiSize} style={style.button} onClick={(e) => {
            e.stopPropagation();
            window.open(`/collection/${event.collection_id}`);
          }}>View Collection</Button>
        </Row>}
        {(identity.user_id === event.user_id || isAdmin()) && <IconButton size={uiSize} style={style.button}
          onClick={async () => {
            const csv = await Promise.all([
              oauth('GET', 'community-event-user', {community_event_id: event.community_event_id}),
              oauth('GET', 'community-event-watcher', {community_event_id: event.community_event_id}),
            ]).then(([usersResponse, watchersResponse]) => {
              const users = get(usersResponse, 'json.community_events_users') || [];
              const watchers = get(watchersResponse, 'json.community_events_watchers') || [];
              return 'email,first_name,last_name,status\n' + concat(
                ...map({
                  'viewed recording': watchers,
                  'interested': filter(users, ({ attended }) => !parseRestBoolean(attended)),
                  'attended': filter(users, ({ attended }) => parseRestBoolean(attended)),
                }, (users, status) => {
                  return map(users, (u) => {
                    return map(
                      [u.contact_email, u.contact_first_name, u.contact_last_name, status],
                      JSON.stringify
                    ).join(',');
                  });
                })
              ).join('\n');
            });
            createDownload(`data:text/csv;charset=utf-8,${csv}`, 'attendees.csv');
          }}
          Icon={DownloadIcon}
        >Attendees</IconButton>}
        {[company_type, 'ALL'].indexOf(event.audience) > -1 && <ShowPopup popup={SharePopup} event={event}
          render={({ onClick }) => {
            return <Button size={uiSize} style={style.button} onClick={onClick}>Share</Button>;
          }}
        />}
      </Row>
    </Col>
  </Background>;
};

export default React.memo(Event);
